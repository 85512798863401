import consumer from "./consumer";

document.addEventListener("turbo:load", () => {
  let element;
  element = document.getElementById('social-proof');

  if (element) {
    consumer.subscriptions.create(
      { channel: "SocialProofChannel", code: 'social_proof_chanel' },
      {
        connected() {},
        disconnected() {},
  
        received(data) {
          this.clearItems();
          this.appendData(data);
        },
  
        clearItems() {
          const items = document.querySelectorAll('#social-proof-wrapper');
          items.forEach((item) => item.remove());
        },
  
        appendData({ html }) {
          const body = document.querySelector('body');
  
          if (body) {
            const wrapper = '<div id="social-proof-wrapper"></div>';
            body.insertAdjacentHTML("afterbegin", wrapper);
            document.getElementById("social-proof-wrapper").innerHTML = html;
          }
        }
      }
    );
  }
});
