import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = ['subscriptionsDisplay'];

  PUSH_WORKER_URL        =  document.querySelector('meta[name="push-worker-url"]').getAttribute("content");
  APPLICATION_SERVER_KEY =  document.querySelector('meta[name="application-server-key"]').getAttribute("content");
  CONTEXT                =  document.body.dataset.context
  CONTEXT_ID             =  document.body.dataset.contextId
  CONTEXT_MAPPING        =  {
                              merchants:  'Merchant',
                              patrons:    'Patron',
                              admin:      'Admin',
                              agents:     'Agent'
                            }

  REGISTRATION_STATES    =  {
                              granted:  'granted',
                              denied:   'denied',
                            }

  connect() {}

  triggerNotificationRegistration() {
    if ("Notification" in window) {
      this._requestPermission();
    } else {
      console.warn("Push notifications not supported.");
    }
  }

  registerServiceWorker() {
    // Check if the browser supports service workers
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(this.PUSH_WORKER_URL)
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((existingSubscription) => {
              if (!existingSubscription) {
                this._subscribeToPushNotifications(serviceWorkerRegistration)
              } else {
                this._saveSubscription(existingSubscription);
              }
            });
        })
        .catch((error) => {
          console.error("Error during registration Service Worker:", error);
        });
    }
  }

  _saveSubscription(subscription) {
    // Extract necessary subscription data
    const endpoint = subscription.endpoint;
    const p256dh   = btoa(
      String.fromCharCode.apply(null, new Uint8Array(subscription.getKey("p256dh")))
    );
    const auth = btoa(
      String.fromCharCode.apply(null, new Uint8Array(subscription.getKey("auth")))
    );

    const pushable_type = this.CONTEXT_MAPPING[this.CONTEXT];

    let form = new FormData();

    form.append('subscription[endpoint]', endpoint);
    form.append('subscription[p256dh]', p256dh);
    form.append('subscription[auth]', auth);
    form.append('subscription[pushable_type]', pushable_type);
    form.append('subscription[pushable_id]', this.CONTEXT_ID);

    Rails.ajax({
      type:     'post',
      dataType: 'json',
      url:      '/api/v1/push/subscriptions',
      data:     form,
      success: (response) => {
        this.subscriptionsDisplayTarget.innerHTML = response.data.html;
      },
      error: (response) => {
        console.error(response);
      },
    });
  }

  _requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === this.REGISTRATION_STATES.granted) {
        this.registerServiceWorker();
      } else if (permission === this.REGISTRATION_STATES.denied) {
        console.warn("User rejected to allow notifications.");
      } else {
        console.warn("User still didn't give an answer about notifications.");
      }
    });
  }

  _subscribeToPushNotifications(serviceWorkerRegistration) {
    serviceWorkerRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: this._urlBase64ToUint8Array(this.APPLICATION_SERVER_KEY),
      })
      .then((subscription) => {
        this._saveSubscription(subscription);
      });
  }

  _urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
}
