import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"
let debounce = require("lodash/debounce");

export default class extends Controller {
  static targets = ["dropdownItem", "dropdownList", "input", "hiddenInput", "searchUrl"];

  connect() {
    this.filterItems = debounce(this.filterItems, 100).bind(this);
  }

  selectItem(e) {
    e.preventDefault();

    const target      = e.target;
    const optionText  = target.firstChild.nodeValue;
    const optionValue = target.dataset.value;
    const fieldName   = target.dataset.field;

    this.inputTarget.value        = optionText;
    this.hiddenInputTarget.value  = optionValue;
  }

  filterItems(e) {
    this.appendSearching();

    const currentText = e.target.value;
    const form        = new FormData();
    const data        = { text: currentText, merchant_id: this.inputTarget.dataset.merchantId };

    form.append('query', JSON.stringify(data));

    Rails.ajax({
      type:     'post',
      dataType: 'json',
      url:      this.inputTarget.dataset.searchUrl,
      data:     form,
      success:  (response) => {
        this.refreshList();
        this.dropdownListTarget.innerHTML = '';

        response.data.forEach((i) => {
          this.dropdownListTarget.insertAdjacentHTML("beforeend", i);
        });

        if (response.data.length === 0) {
          this.appendEmptyItem();
        }
      },
      error: () => this.appendEmptyItem(),
    });
  }

  refreshList() {
    this.dropdownListTarget.innerHTML = '';
  }

  appendEmptyItem() {
    let li = document.createElement('li');

    li.classList.add('dropdown-item');
    li.textContent = 'No results';

    this.dropdownListTarget.appendChild(li);
  }

  appendSearching() {
    this.refreshList();
    let li = document.createElement('li');

    li.classList.add('dropdown-item');
    li.textContent = 'Searching ...';

    this.dropdownListTarget.appendChild(li);
  }
}
