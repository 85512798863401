// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";

import controllers    from "./**/*_controller.js";
import tScontrollers  from "./**/*_controller.ts";

const application = Application.start();

controllers.concat(tScontrollers).forEach((controller) => {
  application.register(controller.name, controller.module.default);
});
