import { Controller } from "@hotwired/stimulus";
import { Datepicker } from 'vanillajs-datepicker';

export default class extends Controller {
  static targets = ["datePickable"];

  connect() {
    this.datePickableTargets.forEach(elem => {
      new Datepicker(elem, {
        format: 'dd/mm/yyyy',
        minDate: elem.dataset.minDate,
        maxDate: elem.dataset.maxDate
      });
    });
  }
}
