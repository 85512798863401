import { Controller } from "@hotwired/stimulus";
import { loading } from '../../utils/_loading';

export default class extends Controller {
  static targets = ['select']

  loadProfessional(e) {
    const bookingContainer = document.querySelector('[data-booking-container]');
    bookingContainer.innerHTML = loading('Fetching details');

    const baseUrl    = e.target.dataset.url;
    const merchantId = e.target.value;

    window.location = `${baseUrl}?merchant_id=${merchantId}`;
  }

}
