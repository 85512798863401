import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailItem", "emailsContainer"];

  connect() {
    this.clonedNode = this.emailItemTargets[0].cloneNode(true);
  }

  add() {
    this.emailsContainerTarget.append(this.clonedNode);
    this.clonedNode = this.emailItemTargets[this.emailItemTargets.length - 1].cloneNode(true);
  }

  remove(event) {
    if (this.emailItemTargets.length > 1) {
      event.target.closest(".row").remove();
    }
  }
}
