export default(data) => (
  data === null ||
  data === undefined ||
  data === '' ||
  // data === false ||
  // data === 0 ||
  // data === '0' ||
  (Array.isArray(data) && data.length === 0) ||
  (typeof data === 'object' && Object.keys(data).length === 0)
);
