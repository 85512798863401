import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updateTime();
    this.intervalId = setInterval(this.updateTime, 1000);
  }

  updateTime() {
    const now           = new Date();
    const hours         = String(now.getHours()).padStart(2, '0');
    const minutes       = String(now.getMinutes()).padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    if (this.currentTime === formattedTime) {
      return;
    }

    const displayEl     = document.querySelector('[data-time-display]')
    displayEl.innerHTML = formattedTime;
    this.currentTime    = formattedTime;
  }

  disconnect() {
    clearInterval(this.intervalId);
  }
}
