import { Controller } from "@hotwired/stimulus";
import {
  GET_SS_STATE,
  SET_SS_STATE,
  REMOVE_SS_STATE,
  SS_ORDER_SESSION_KEYS,
} from "../../utils/_sessionstorage";
import isEmpty from "../../utils/_empty";
import Rails from "@rails/ujs";
import { Offcanvas, Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "addToOrderButton",
    "orderSessionId",
    "activeOrdersItemCount",
    "activeOrdersTotal",
    "activeCartBanner",
    "activeOrderIncrement",
    "activeOrderDecrement",
    "orderSummary",
    "offcanvasWrapper",
    "mainForm",
    "cardAuthorizations",
    "paymentActions",
    "paymentConfirmation",
    "newSessionEmail",
    "newSessionPassword",
    "newSessionRememberMe",
    "newRegistrationFirstName",
    "newRegistrationLastName",
    "newRegistrationEmail",
    "newRegistrationPassword",
    "newRegistrationAcceptTerms",
  ];

  connect() {
    if (document.querySelector("#offcanvas-smart-orders")) {
      const bsOffcanvas = new Offcanvas("#offcanvas-smart-orders");

      if (this.locationParams().continue_signup) {
        bsOffcanvas.toggle();
        this.triggerSignUp();
      }

      if (this.locationParams().continue_signin) {
        bsOffcanvas.toggle();

        this.triggerSignIn();
      }
    }

    this.initializeActiveCartDetails();
  }

  incrementOrder(event) {
    event.preventDefault();
    const identifier = event.target.dataset.identifier;
    const inputEl = document.querySelector(
      `input[data-identifier="${identifier}"]`,
    );

    if (inputEl !== null) {
      inputEl.stepUp();
    }
  }

  decrementOrder(event) {
    event.preventDefault();
    const identifier = event.target.dataset.identifier;
    const inputEl = document.querySelector(
      `input[data-identifier="${identifier}"]`,
    );

    if (inputEl !== null) {
      if (inputEl.value > 0) {
        inputEl.stepDown();
      }
    }
  }

  triggerOffcanvas(e) {
    e.preventDefault();
    const productId = e.target.dataset.productId;
    const bsOffcanvas = new Offcanvas("#offcanvas-smart-orders");

    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    bsOffcanvas.toggle();

    Rails.ajax({
      type: "get",
      dataType: "json",
      url: `/api/v1/point_of_sale/products/${productId}`,
      success: (response) => {
        this.offcanvasWrapperTarget.innerHTML = response.data.html;
      },
      error: (_err) => {},
    });
  }

  triggerConfirmationModal(e) {
    e.preventDefault();
    const patronId = e.target.dataset.patronId;
    const payEl = document.querySelector("#pay-modal");
    const modal = Modal.getOrCreateInstance(payEl);

    const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;
    const cartItems = JSON.parse(GET_SS_STATE(sessionId)).cart;
    const form = new FormData();

    form.append("patron_id", patronId);
    form.append("products", JSON.stringify(cartItems));

    this.paymentConfirmationTarget.innerHTML = `<div class='p-5'>${this.loadingDiv()}</div>`;

    Rails.ajax({
      type: "post",
      dataType: "json",
      url: "/api/v1/point_of_sale/payment_confirmation",
      data: form,
      success: (response) => {
        this.paymentConfirmationTarget.innerHTML = response.data.html;
      },
      error: (err) => {
        if (err.error || err.message) {
          document
            .querySelector("body")
            .insertAdjacentHTML(
              "afterbegin",
              `<div class="wui-form__meta text-center  wui-form__meta--error wui-form__meta--rounded mb-3 wui-flash-message animate__animated animate__delay-3s animate__fadeOutUp z-index-float">${err.error || err.message}</div>`,
            );

          if (err.data.redirect_url) {
            window.location = err.data.redirect_url;
          }
        } else {
          console.error(err);
        }
      },
    });

    modal.toggle();
  }

  loadingDiv() {
    return '<div class="page-loader d-flex justify-content-center align-items-center flex-column"><div><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div><div class="page-loader-text"></div></div>';
  }

  #getnumberCartProductsOfSameType(userSessionId, selectedProductId) {
    const cart = JSON.parse(GET_SS_STATE(userSessionId)).cart;

    return cart.reduce((total, product) => {
      if (product.productId === selectedProductId) {
        return total + Number(product.quantity);
      }
    }, 0);
  }
  // TODO: Rename to validateProductLimit to not confuse with inventory
  #validateProductQuantity(
    productPurchaseLimit,
    quantity,
    quantityOfSameProductsInCart,
  ) {
    const minimumLimitPerPurchase = 1;
    const totalNumberOfProductOrders = quantityOfSameProductsInCart + quantity;
    const isPurchaseLimitValid =
      productPurchaseLimit >= minimumLimitPerPurchase;
    const doesProductOrderExceedLimit =
      totalNumberOfProductOrders > productPurchaseLimit;

    if (
      isPurchaseLimitValid &&
      (quantity > productPurchaseLimit || doesProductOrderExceedLimit)
    ) {
      window.alert(
        `Purchase limit for this product is ${productPurchaseLimit} items.`,
      );
      return false;
    } else {
      return true;
    }
  }

  addToOrder(e) {
    e.preventDefault();
    const productId = e.target.dataset.productId;
    const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;

    let validityForm = new FormData();
    validityForm.append("product_id", productId);
    validityForm.append(
      "cart_items",
      JSON.stringify(this.cartItems(sessionId)),
    );

    Rails.ajax({
      type: "post",
      dataType: "json",
      url: "/api/v1/point_of_sale/cart/validate_entry",
      data: validityForm,
      success: () => {
        const limitPerPurchase = e.target.dataset.limitPerPurchase;
        const productOptionNames =
          e.target.dataset.productOptionNames.split(",");
        const numberOfproductsOfSameType =
          this.#getnumberCartProductsOfSameType(sessionId, productId);
        const inputEl = document.querySelector(
          `input[data-identifier="${productId}"]`,
        );
        let form = this.mainFormTarget;
        const formData = new FormData(form);
        let selVal = [];

        let strippedId = productId.replaceAll("-", "");

        productOptionNames.forEach((pon) => {
          let regexString = `selected_values[product_option_values][${strippedId}][${pon}][id]`;

          for (const pair of Array.from(formData.entries()).filter(
            (i) => i[0].replaceAll("-", "") === regexString.toString(),
          )) {
            selVal.push(pair[1]);
          }
        });

        if (inputEl) {
          let quantity;

          if (
            this.#validateProductQuantity(
              limitPerPurchase,
              Number(inputEl.value),
              numberOfproductsOfSameType,
            )
          ) {
            quantity = Number(inputEl.value);
          }

          if (quantity === Number(0) || quantity === undefined) {
            return;
          }

          const selectedOptions = selVal;

          let items = { productId, quantity, selectedOptions };
          let cartItems;

          if (isEmpty(GET_SS_STATE(sessionId))) {
            cartItems = [items];
            SET_SS_STATE(sessionId, JSON.stringify({ cart: cartItems }));
          } else {
            cartItems = JSON.parse(GET_SS_STATE(sessionId)).cart;
            cartItems.push(items);

            SET_SS_STATE(sessionId, JSON.stringify({ cart: cartItems }));
          }
          this.setActiveCartDetails(cartItems);
          inputEl.value = 1;
          inputEl.dispatchEvent(new Event("change"));
          this.createNotification(quantity);
        }
      },
      error: (e) => {
        this.createNotification(null, "error", e.message);
        return;
      },
    });
  }

  cartItems(sessionId) {
    return JSON.parse(GET_SS_STATE(sessionId)).cart;
  }

  initializeActiveCartDetails() {
    const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;

    if (sessionId) {
      const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;
      let cartItems;

      if (isEmpty(GET_SS_STATE(sessionId))) {
        SET_SS_STATE(sessionId, JSON.stringify({ cart: [] }));
      } else {
        cartItems = JSON.parse(GET_SS_STATE(sessionId)).cart;

        SET_SS_STATE(sessionId, JSON.stringify({ cart: cartItems }));
      }
      this.setActiveCartDetails(cartItems);
    } else {
      console.error("No order session id");
    }
  }

  setActiveCartDetails(cartItems) {
    if (isEmpty(cartItems)) {
      return;
    }

    const totalItemCount = cartItems.reduce((total, item) => {
      return total + Number(item.quantity);
    }, 0);

    const form = new FormData();

    form.append("products", JSON.stringify(cartItems));

    Rails.ajax({
      type: "post",
      dataType: "json",
      url: "/api/v1/point_of_sale/cost_calculator",
      data: form,
      success: (response) => {
        this.activeOrdersTotalTarget.innerHTML =
          response.data.total_cost_formatted;
        this.activeOrdersItemCountTarget.innerHTML = `${totalItemCount} ${totalItemCount === 1 ? "Item" : "Items"}`;
        this.orderSummaryTarget.innerHTML = response.data.summary_html;

        this.updateCartIndicators(totalItemCount);
      },
      error: (e) => {
        console.error("error", e);
      },
    });
  }

  updateCartIndicators(itemCount) {
    const pageContext = document.querySelector("body").dataset.context;
    let badgePosition = pageContext === "patrons" ? "50" : "100";
    let cartTriggers = document.querySelectorAll("[data-cart-trigger]");

    cartTriggers.forEach((ct) => {
      ct.innerHTML = `<i class="ri-shopping-cart-line" style="pointer-events: none;"></i>
        <span class="position-absolute top-0 start-${badgePosition} translate-middle badge rounded-pill bg-danger" style="pointer-events: none;">
          ${itemCount}
          <span class="visually-hidden" style="pointer-events: none;">Cart Items</span>
        </span>
      `;
    });
  }

  submitOrder(e) {
    e.preventDefault();

    const pageLoader = document.querySelector(".page-loader");
    const pageLoaderText = document.querySelector(".page-loader-text");
    const yieldedContent = document.querySelector(".yielded-content");

    if (pageLoader && pageLoaderText && yieldedContent) {
      pageLoaderText.innerHTML = " ... Preparing Payment ...";
      pageLoader.classList.remove("d-none");
      yieldedContent.classList.add("d-none");
    }

    const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;
    const cartItems = JSON.parse(GET_SS_STATE(sessionId)).cart;
    const form = document.querySelector("#payment-form");
    const formData = new FormData(form);

    formData.append("products", JSON.stringify(cartItems));

    Rails.ajax({
      type: "post",
      dataType: "json",
      url: form.action,
      data: formData,
      success: (response) => {
        this.clearExistingOrderSessions();
        this.activeOrdersTotalTarget.innerHTML = "...";
        this.activeOrdersItemCountTarget.innerHTML = "...";
        this.activeCartBannerTarget.classList.add("d-none");
        const redirectUrl = response.data.redirect_url;
        window.location = redirectUrl;
      },
      error: (err) => {
        if (err.data.redirect_url) {
          window.location = err.data.redirect_url;
          return;
        }

        if (err.error || err.message) {
          document
            .querySelector("body")
            .insertAdjacentHTML(
              "afterbegin",
              `<div class="wui-form__meta text-center  wui-form__meta--error wui-form__meta--rounded mb-3 wui-flash-message animate__animated animate__delay-3s animate__fadeOutUp z-index-float">${err.error || err.message}</div>`,
            );
        } else {
          console.error(err);
        }
      },
    });
  }

  clearExistingOrderSessions() {
    const partialKey =
      this.orderSessionIdTarget.dataset.orderSessionId.split("_")[0];
    SS_ORDER_SESSION_KEYS(partialKey).forEach(REMOVE_SS_STATE);
  }

  clearOrder(event) {
    event.preventDefault();
    const sessionId = this.orderSessionIdTarget.dataset.orderSessionId;
    SET_SS_STATE(sessionId, JSON.stringify({ cart: [] }));

    this.orderSummaryTarget.innerHTML = this.emptyStateHtml();

    this.updateCartIndicators(0);
  }

  createNotification(itemCount, type = "success", content = null) {
    const newFlash = document.createElement("a");
    const newContent =
      content == null
        ? `${itemCount} items added - ${this.viewCartLinkHtml()}`
        : content;

    newFlash.classList.add(
      "added-to-cart-notification",
      "wui-form__meta",
      "text-center",
      `wui-form__meta--${type}`,
      "wui-form__meta--rounded",
      "mb-3",
      "wui-flash-message",
      "animate__animated",
      "animate__delay-5s",
      "animate__fadeOutUp",
      "z-index-float",
      "text-decoration-none",
    );

    newFlash.style = `top: ${window.scrollY + 20}px`;

    newFlash.insertAdjacentHTML("afterbegin", newContent);

    document.body.append(newFlash);

    setTimeout(() => {
      newFlash.remove();
    }, 8000);
  }

  emptyStateHtml() {
    return `<div class="wui-empty-state wui-no-shadow">
      <div class="wui-empty-state__icon">
        <i class="ri-shopping-cart-line"></i>
      </div><div class="wui-empty-state__title">Your cart is empty</div>
      <div class="wui-empty-state__message">Add items to your cart to get started</div>
    </div>`;
  }

  viewCartLinkHtml() {
    return `<a class="text-dark" href="#" data-cart-trigger="true">View Cart</a>`;
  }

  triggerSignUp() {
    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    Rails.ajax({
      type: "get",
      dataType: "json",
      url: "/patrons/sign_up",
      success: (response) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML(
          "afterbegin",
          response.html,
        );
      },
      error: (_err) => {},
    });
  }

  triggerSignIn() {
    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    Rails.ajax({
      type: "get",
      dataType: "json",
      url: "/patrons/sign_in",
      success: (response) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML(
          "afterbegin",
          response.html,
        );
      },
      error: (_err) => {},
    });
  }

  submitSignIn(e) {
    e.preventDefault();

    const email = this.newSessionEmailTarget.value;
    const password = this.newSessionPasswordTarget.value;
    const rememberMe = this.newSessionRememberMeTarget.value;
    const formData = new FormData();

    formData.append("patron[email]", email);
    formData.append("patron[password]", password);
    formData.append("patron[remember_me]", rememberMe);

    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    Rails.ajax({
      type: "post",
      dataType: "json",
      data: formData,
      url: "/patrons/sign_in",
      success: (response) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML(
          "afterbegin",
          response.html,
        );
      },
      error: (err) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML("afterbegin", err.html);
      },
    });
  }

  submitSignUp(e) {
    e.preventDefault();

    const firstName = this.newRegistrationFirstNameTarget.value;
    const lastName = this.newRegistrationLastNameTarget.value;
    const email = this.newRegistrationEmailTarget.value;
    const password = this.newRegistrationPasswordTarget.value;
    const acceptTerms = this.newRegistrationAcceptTermsTarget.value;

    const formData = new FormData();

    formData.append("patron[email]", email);
    formData.append("patron[password]", password);
    formData.append("patron[profile_attributes][first_name]", firstName);
    formData.append("patron[profile_attributes][last_name]", lastName);
    formData.append("patron[accept_terms]", acceptTerms);

    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    Rails.ajax({
      type: "post",
      dataType: "json",
      data: formData,
      url: "/patrons/",
      success: (response) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML(
          "afterbegin",
          response.html,
        );
      },
      error: (err) => {
        this.offcanvasWrapperTarget.innerHTML = "";
        this.offcanvasWrapperTarget.insertAdjacentHTML("afterbegin", err.html);
      },
    });
  }

  locationParams() {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }

  disconnect() {
    // REMOVE_SS_STATE(this.orderSessionIdTarget.dataset.orderSessionId);
  }
}
