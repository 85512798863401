import consumer from "./consumer";

document.addEventListener("turbo:load", () => {
  let channel_code, element;

  element = document.getElementById("channel-code");

  if (element) {
    channel_code = element.getAttribute("data-channel-code");

    consumer.subscriptions.create(
      { channel: "OrdersChannel", code: channel_code },
      {
        connected() {},

        disconnected() {},

        received(data) {
          this.appendData(data);
        },

        appendData({ html }) {
          const emptyWrapper = this._getEmptyWrapper();

          if (emptyWrapper) {
            const tableWrapper = '<div id="orders-table-wrapper"></div>';
            emptyWrapper.insertAdjacentHTML("afterend", tableWrapper);
            emptyWrapper.remove();
          }

          document.getElementById("orders-table-wrapper").innerHTML = html;
        },

        _getEmptyWrapper() {
          return document.getElementById("orders-empty-wrapper");
        },
      }
    );
  }
});
