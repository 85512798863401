import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["validatable", "errorsContainer", "errorsList"];

  errorsHash = {};

  connect() {}

  submitForm(event) {
    this.resetErrors();

    const form          = event.target;
    const inputs        = form.querySelectorAll("[required=required]");
    const missingFields = this.buildMissingFiledsList(inputs, form);
    const listItems     = this.buildErrorListItems(missingFields);

    if (this.hasErrors(listItems)) {
      this.showErrors(listItems);
    }

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (true) {
      event.preventDefault();
    }
  }

  buildMissingFiledsList(formInputs, form) {
    return Array.from(formInputs).map((input) => {
      let validations = {};

      if (input.dataset.validate) {
        validations = JSON.parse(input.dataset.validate);
      }

      Object.values(validations).forEach((_validation) => {});

      if (input.value === "" || input.value === null || input.value === undefined) {
        return input.dataset.name;
      }
    });
  }

  buildErrorListItems(missingFields) {
    return missingFields.filter(i => i).map((field) => `<li>${field}</li>`);
  }

  hasErrors(listItems) {
    return listItems.length > 0;
  }

  resetErrors() {
    this.errorsContainerTarget.classList.add('d-none');
    this.errorsListTarget.innerHTML = '';
  }

  showErrors(listItems) {
    this.errorsContainerTarget.classList.remove('d-none');
    this.errorsListTarget.innerHTML = listItems.join('');
  }
}
