import { Controller } from "@hotwired/stimulus";
import Daily from "@daily-co/daily-js"

export default class extends Controller {
  static targets = ['video'];

  connect() {
    window.Daily = Daily;

    let callUrl = this.videoTarget.dataset.callUrl;
    let token = this.videoTarget.dataset.token;

    const options = {
      showLeaveButton: true,
      token: token,
      iframeStyle: {
        width: '100%',
        height: '100%',
      },
      url: callUrl
    }

    const MY_IFRAME = document.querySelector('iframe');

    MY_IFRAME.setAttribute(
      'allow',
      'microphone; camera; autoplay; display-capture'
    );

    let call = Daily.wrap(MY_IFRAME, options);

    call.join();
  }
}
