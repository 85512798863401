import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["notice"];

  copy(event) {
    navigator.clipboard.writeText(event.target.getAttribute("data-token"));
    this.toggleNotice();
  }

  toggleNotice() {
    this.noticeTarget.classList.remove("d-none");

    setTimeout(() => {
      this.noticeTarget.classList.add("d-none");
    }, 3000);
  }
}
