import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'formPartial',
    'form',
    'partialWrapper',
    'selectedType',
    'emptyState',
  ];

  initialize() {
    // Store the partials
    this.partialClones = this.formPartialTargets.slice();

    // Remove the partials from the DOM
    this.formPartialTargets.forEach(partial => {
      partial.remove();
    });

    // Load the partial if there's a value (Edit page)
    if (this.selectedTypeTarget && this.selectedTypeTarget.value !== '') {
      this.selectType(this.selectedTypeTarget.value);
    }
  }


  selectType(eventOrString) {
    this._removePartialsFromDOM();
    this.emptyStateTarget.classList.add("d-none");

    let selectValue;
    typeof eventOrString === 'string'
      ? (selectValue = eventOrString)
      : (selectValue = eventOrString.target.value);

    switch (selectValue) {
      case "woo_commerce":
        this._appendPartial("woo_commerce");
        break;
      default:
        this.emptyStateTarget.classList.remove("d-none");
        return;
    }
  }

  _findPartial(name, collection) {
    return collection.find(
      (element) => element.getAttribute('data-form-partial') === name
    );
  }

  _appendPartial(partialName) {
    const partial = this._findPartial(partialName, this.partialClones);
    partial.classList.remove('d-none');
    this.partialWrapperTarget.append(partial);
  }

  _removePartialsFromDOM() {
    this.formPartialTargets.forEach((element) => {
      element.classList.add('d-none');
      element.remove();
    });
  }
}
