import { Controller } from "@hotwired/stimulus";
import { SET_LS_STATE } from "../utils/_localstorage"

export default class extends Controller {
  LOCATION_KEY = 'ur-current-location';

  connect() {
    navigator.geolocation.getCurrentPosition(position => {
      const data = JSON.stringify({
        latitude:   position.coords.latitude,
        longitude:  position.coords.longitude,
        accuracy:   position.coords.accuracy
      });

      SET_LS_STATE(this.LOCATION_KEY, data);
    });
  }
}
