import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'package' ]

  initialize() {
    this.setPackageDetails();
  }

  setPackageDetails() {
    this.packageTargets.forEach(item => {
      const defaultPrice = item.dataset.packageMonthlyPrice;

      item.querySelector('select').value            = 'Monthly';
      item.querySelector('#packagePrice').innerHTML = defaultPrice;
    });
  }

  calculateChange(event) {
    const selectedName = event.target.dataset.id;
    const newPeriod    = event.target.value;

    const selected = this.packageTargets.find(item => {
      return item.dataset.packageName === selectedName
    });

    const monthlyPrice = selected.dataset.packageMonthlyPrice;
    const yearlyPrice  = selected.dataset.packageYearlyPrice;

    if (newPeriod === 'Yearly') {
      selected.querySelector('#packagePrice').innerHTML = yearlyPrice;
    } else {
      selected.querySelector('#packagePrice').innerHTML = monthlyPrice;
    }
  }
}
