import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["appendable", "formprepend", "fieldsContainer"];
  connect() {}

  appendToForm(e) {
    const time            = new Date().getTime()
    const objString       = e.target.dataset.formPrepend;
    const newObjectString = objString.replaceAll("new_record", time);

    this.fieldsContainerTarget.insertAdjacentHTML('beforeend', newObjectString);
  }

  convertStringToHTML(str) {
    let parser = new DOMParser();
    let doc    = parser.parseFromString(str, 'text/html');

    return doc.body.firstChild;
  };

  remove(_e) {}
}
