import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'customRewardField',
    'percentRewardField',
    'selectedRewardType'
  ];

  connect() {
    // Select Reward Type
    if (this.hasSelectedRewardTypeTarget && this.selectedRewardTypeTarget.value !== '') {
      this.toggleRewardType(this.selectedRewardTypeTarget.value);
    }
  }

  toggleRewardType(eventOrString) {
    let selectValue;
    typeof eventOrString === 'string'
      ? (selectValue = eventOrString)
      : (selectValue = eventOrString.target.value);

    if (selectValue === 'Custom') {
      this.customRewardFieldTarget.classList.remove('d-none');
      this.percentRewardFieldTarget.classList.add('d-none');
    }

    if (selectValue === 'Percent') {
      this.customRewardFieldTarget.classList.add('d-none');
      this.percentRewardFieldTarget.classList.remove('d-none');
    }
  }
}
