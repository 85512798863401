import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'autoRedeemFields',
    'autoRedeemCheckbox',
    'infiniteCheckbox',
    'expiryDate',
    'splitCheckbox',
    'splitCount',
    'usageLimit',
    'generateButton',
    'autoRedeemButton',
    'autoRedeemModal',
    'currentTotal',
    'fullTotal',
    'actionsWithoutCurrentAmount',
    'actionsWithCurrentAmount',
    'valuesWithoutCurrentAmount',
    'valuesWithCurrentAmount',
    'notice',
    'errorNotice'
  ];

  initialize() {
    this.toggleInfiniteUsage(this.infiniteCheckboxTarget.checked);
    this.toggleCounts(this.splitCheckboxTarget.checked);
    this.toggleAutoRedeem(this.autoRedeemCheckboxTarget.checked);
  }

  connect() {
    this.autoRedeemModalTarget.addEventListener('show.bs.modal', event => {
      // ========= Reset Everything
      this.actionsWithoutCurrentAmountTargets.forEach(i => i.classList.add('d-none'));
      this.actionsWithCurrentAmountTargets.forEach(i => i.classList.remove('d-none'));

      this.valuesWithoutCurrentAmountTargets.forEach(i => i.classList.add('d-none'));
      this.valuesWithCurrentAmountTargets.forEach(i => i.classList.remove('d-none'));

      this.noticeTarget.classList.remove('d-none');
      this.errorNoticeTarget.classList.add('d-none');
      // =========

      const form        = event.target.closest('form');
      const formData    = new FormData(form);

      Rails.ajax({
        type:     'post',
        dataType: 'json',
        url:      '/points_calculator',
        data:     formData,
        success: (response) => {
          const current_total_text = `${response.data.current_points}`;
          const full_total_text    = `${response.data.total_points}`;

          if (response.data.show_total_only) {
            this.actionsWithoutCurrentAmountTargets.forEach(i => i.classList.remove('d-none'));
            this.actionsWithCurrentAmountTargets.forEach(i => i.classList.add('d-none'));

            this.valuesWithoutCurrentAmountTargets.forEach(i => i.classList.remove('d-none'));
            this.valuesWithCurrentAmountTargets.forEach(i => i.classList.add('d-none'));
          }

          this.currentTotalTargets.forEach(i => i.innerHTML = current_total_text);
          this.fullTotalTargets.forEach(i => i.innerHTML = full_total_text);
        },
        error: (error) => {
          this.currentTotalTargets.forEach(i => i.innerHTML = 'Error Calculating');
          this.fullTotalTargets.forEach(i => i.innerHTML = 'Error Calculating');

          this.actionsWithoutCurrentAmountTargets.forEach(i => i.classList.add('d-none'));
          this.actionsWithCurrentAmountTargets.forEach(i => i.classList.add('d-none'));

          this.noticeTarget.classList.add('d-none');
          this.errorNoticeTarget.classList.remove('d-none');
        },
      });
    });
  }

  toggleAutoRedeem(eventOrBool) {
    if (this.getValue(eventOrBool)) {
      this.autoRedeemFieldsTarget.classList.remove('d-none');
      this.autoRedeemButtonTarget.classList.remove('d-none');
      this.generateButtonTarget.classList.add('d-none');
    } else {
      this.autoRedeemFieldsTarget.classList.add('d-none');
      this.autoRedeemButtonTarget.classList.add('d-none');
      this.generateButtonTarget.classList.remove('d-none');
    }
  }

  toggleInfiniteUsage(eventOrBool) {
    if (this.getValue(eventOrBool)) {
      this.disableFields([
        this.expiryDateTarget,
        this.splitCountTarget,
        this.usageLimitTarget,
        this.splitCheckboxTarget,
      ]);
      this.splitCheckboxTarget.checked = false;
      this.infiniteCheckboxTarget.checked = true;
    } else {
      this.enableFields([
        this.expiryDateTarget,
        this.usageLimitTarget,
        this.splitCheckboxTarget,
      ]);

      this.infiniteCheckboxTarget.checked = false;
    }
  }

  toggleCounts(eventOrBool) {
    if (this.getValue(eventOrBool)) {
      this.enableFields([this.splitCountTarget]);
      this.disableFields([this.usageLimitTarget]);
    } else {
      this.disableFields([this.splitCountTarget]);
      this.enableFields([this.usageLimitTarget]);
    }
  }

  getValue(eventOrBool) {
    return typeof eventOrBool === 'boolean'
      ? eventOrBool
      : eventOrBool.target.checked;
  }

  disableFields(fields) {
    fields.forEach(field => {
      field.disabled = true;
    })
  }

  enableFields(fields) {
    fields.forEach((field) => {
      field.disabled = false;
    });
  }
}
