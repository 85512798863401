import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["maskable"];
  connect() {
    const defaultMaskFormat = "(99)99 9999999";

    this.maskableTargets.forEach(element => {
      const maskFormat = element.dataset.format || defaultMaskFormat;
      const maskObject = new Inputmask(maskFormat)

      maskObject.mask(element);
    });
  }
}
