import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["product", "productContainer"];

  connect() {
    this.clonedNode = this.productTargets[0].cloneNode(true);
  }

  add() {
    this.productContainerTarget.append(this.clonedNode);
    this.clonedNode = this.productTargets[this.productTargets.length - 1].cloneNode(true);
  }

  remove(event) {
    if (this.productTargets.length > 1) {
      event.target.closest(".row").remove();
    }
  }
}
