import { Controller } from "@hotwired/stimulus";
import { debounce } from '../../utils/_debounce';
import { loading } from '../../utils/_loading';

export default class extends Controller {
  static targets = ['product', 'search', 'container'];

  connect() {
    this.originalList = this.productTargets;

    this.searchTarget.addEventListener('keyup', debounce((e) => {
      this.filterProducts();
    }, 250));
  }

  filterProducts() {
    this.containerTarget.innerHTML = loading();

    const searchTerm = this.searchTarget.value;

    let filtered = this.originalList.filter((p) => {
      let text = p.querySelector('.wui-booking-step__heading').textContent;
      return this.isFuzzyMatch(text.toLowerCase(), searchTerm.toLowerCase());
    });

    if (filtered.length === 0) {
      let noResultsDiv = document.createElement('div');
      noResultsDiv.classList.add('p-2', 'text-center', 'fs-6');
      noResultsDiv.textContent = 'No results';

      this.containerTarget.innerHTML = '';

      this.containerTarget.appendChild(noResultsDiv);
    } else {
      this.containerTarget.innerHTML = '';

      filtered.forEach((div) => {
        this.containerTarget.appendChild(div);
      });
    }
  }

  isFuzzyMatch(item, searchTerm) {
    let searchIndex = 0;

    for (let i = 0; i < item.length; i++) {
      if (item[i] === searchTerm[searchIndex]) {
        searchIndex++;
      }
      if (searchIndex === searchTerm.length) {
        return true;
      }
    }

    return false;
  }
}
