import { Controller } from "@hotwired/stimulus";
import { loading } from '../utils/_loading';

export default class extends Controller {
  static targets = [
    'dates',
    'label',
    'stepOneForm',
    'stepTwoForm',
    'emptyState',
    'formDetails',
    'timeSlot',
    'product',
    'noSlots'
  ];
  static values  = { calendarUrl: String, merchant: String, slug: String }

  connect() {}

  filterSlotsFor(timeOfDay) {
    const slotMapping = {
      'morning':    [0,1,2,3,4,5,6,7,8,9,10,11],
      'afternoon':  [12,13,14,15,16,17],
      'evening':    [18,19,20,21,22,23]
    }

    this.timeSlotTargets.forEach((ts, index) => {
      if (!slotMapping[timeOfDay].includes(parseInt(ts.dataset.timeSlot))) {
        ts.classList.add('d-none');
      } else {
        ts.classList.remove('d-none');
      }
    })

    const visible = this.timeSlotTargets.filter(ts => !ts.classList.contains('d-none'));

    if (visible[0]) {
      visible[0].querySelector('input').checked = true;
    } else {
      this.noSlotsTarget.classList.remove('d-none');
    }

  }

  filterSlots(e) {
    this.noSlotsTarget.classList.add('d-none');
    this.filterSlotsFor(e.target.htmlFor);
  }

  selectedProduct() {
    return this.productTargets.find(x => {
      return x.querySelector('input:checked');
    });
  }

  resetAvailability() {
    this.formDetailsTarget.classList.add('d-none');
    this.emptyStateTarget.classList.remove('d-none');
  }
}
