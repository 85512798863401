import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailInput", "successMessage", "errorMessage"];

  onSuccess() {
    this.emailInputTarget.classList.add('d-none');
    this.errorMessageTarget.classList.add('d-none');

    this.successMessageTarget.classList.remove('d-none');
  }

  onError(response) {
    const errorMsg = response.detail[0]["message"];
    this.errorMessageTarget.innerHTML = errorMsg;
    this.errorMessageTarget.classList.remove('d-none');
  }
}
