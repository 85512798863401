import { Controller } from "@hotwired/stimulus";
import { Offcanvas } from "bootstrap";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['offcanvasWrapper'];

  connect() {}

  triggerOffcanvas(e) {
    e.preventDefault();
    this.offcanvasWrapperTarget.innerHTML = this.loadingDiv();

    const orderId     = e.target.dataset.orderId;
    const bsOffcanvas = new Offcanvas('#offcanvas-order-quick-view');

    bsOffcanvas.toggle();

    Rails.ajax({
      type:     'get',
      dataType: 'json',
      url:      `/api/v1/point_of_sale/orders/${orderId}`,
      success:  (response) => {
        const wrapper     = document.querySelector("#offcanvas-order-quick-view");
        wrapper.innerHTML = response.data.html;
      },
      error:    (err) => {
        console.error(err);
      },
    });
  }

  loadingDiv() {
    return '<div class="page-loader d-flex justify-content-center align-items-center flex-column"><div><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div><div class="page-loader-text"> ... Fetching Order Details ...</div></div>';
  }

  updateStatus(e) {
    const orderId   = e.target.dataset.orderId;
    const newStatus = e.target.value;
    const form      = new FormData();

    const data = {
      id:     orderId,
      status: newStatus,
      page:   this.locationParams().page
    };

    form.append('order', JSON.stringify(data));

    Rails.ajax({
      type:     'put',
      dataType: 'json',
      url:      `/api/v1/point_of_sale/orders/${orderId}`,
      data:     form,
      success:  (response) => {
        const wrapper     = document.querySelector("#offcanvas-order-quick-view");
        wrapper.innerHTML = response.data.html;
      },
      error:    (err) => {
        console.error(err);
      },
    });
  }

  updateOrderItemStatus(e) {
    const orderId     = e.target.dataset.orderId;
    const orderItemId = e.target.dataset.orderItemId;
    const newStatus   = e.target.value;
    const form        = new FormData();

    const data = {
      id:      orderItemId,
      status:  newStatus,
      page:    this.locationParams().page
    };

    form.append('order_item', JSON.stringify(data));

    Rails.ajax({
      type:     'put',
      dataType: 'json',
      url:      `/api/v1/point_of_sale/orders/${orderId}/order_items/${orderItemId}`,
      data:     form,
      success:  (response) => {
        const wrapper     = document.querySelector("#offcanvas-order-quick-view");
        wrapper.innerHTML = response.data.html;
      },
      error:    (err) => {
        console.error(err);
      },
    });
  }

  locationParams() {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }
}
