import { Controller } from "@hotwired/stimulus";
import { loading } from '../utils/_loading';

export default class extends Controller {
  static targets = [
    'dates',
    'label',
    'emptyState',
    'formDetails',
    'timeSlot',
    'product',
    'noSlots'
  ];

  static values  = {
    calendarUrl: String,
    merchant:    String,
    slug:        String
  }

  SELECTED_COLOUR = '#ececf9';
  INITIAL_COLOUR  = '#f8f9fa';

  selectDirection(e) {
    e.preventDefault();
    const initialDatesHeight = this.datesTarget.clientHeight;

    this.datesTarget.innerHTML = loading('Getting dates');
    this.datesTarget.classList.add('p-4', 'justify-content-center', 'd-flex');
    this.datesTarget.style.height = `${initialDatesHeight}px`;

    const direction = e.target.dataset.direction;

    let form               = new FormData();
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;
    const urlParams        = new URLSearchParams(window.location.search);

    form.append('calendar', JSON.stringify({
      direction: direction,
      merchant: this.merchantValue,
      merchant_id: this.selectedMerchantId(),
      slug:     this.slugValue,
      current_selections: {
        month: urlParams.get('month') || (new Date().getMonth() + 1),
        year:  urlParams.get('year')  || new Date().getFullYear()
      }
    }));

    fetch(this.calendarUrlValue, {
      method: "POST",
      body: form,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(result => {
      history.pushState(null, '', result.data.new_url.toString());
      this.datesTarget.classList.remove('p-4', 'justify-content-center', 'd-flex');

      this.labelTarget.innerHTML    = result.data.label
      this.datesTarget.innerHTML    = result.data.html;
      this.datesTarget.style.height = 'initial';
    })
  }

  selectDate(e) {
    e.preventDefault();
    // Shouldnt have to do this, but doing it for now
    e.target.querySelector('input').checked = true;

    Array.from(document.querySelectorAll('.wui-calendar__date--active')).forEach((dt) => { dt.style.backgroundColor = this.INITIAL_COLOUR; });
    e.target.style.backgroundColor = this.SELECTED_COLOUR;

    const dateId                        = e.target.dataset.id;
    const initialDatesHeight            = document.querySelector('.wui-calendar').clientHeight;
    this.formDetailsTarget.style.height = `${initialDatesHeight}px`;
    this.formDetailsTarget.innerHTML    = loading('Fetching details');

    this.formDetailsTarget.classList.remove('d-none');
    this.emptyStateTarget.classList.add('d-none');

    let form               = new FormData();
    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;

    form.append('availability', JSON.stringify({
      date_id:  dateId,
      merchant: this.merchantValue,
      slug:     this.slugValue,
      service_duration: this.selectedProduct().dataset.duration,
      merchant_id: this.selectedMerchantId()
    }));

    fetch('/bookings/availability', {
      method: "POST",
      body: form,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(result => {
      this.formDetailsTarget.style.height = 'initial';
      this.formDetailsTarget.style.minHeight = `${initialDatesHeight}px`;
      this.formDetailsTarget.innerHTML = result.data.html;
      this.filterSlotsFor('morning');

      this.scrollToNext();
    })
  }

  scrollToNext() {
    const slotsSelectionDiv = document.querySelector('[data-target="bookings.stepTwoForm"]');
    if (!slotsSelectionDiv) {
      return;
    }

    const rect        = slotsSelectionDiv.getBoundingClientRect();
    const scrollTop   = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft  = window.pageXOffset || document.documentElement.scrollLeft;


    const position = {
      top:  rect.top + scrollTop,
      left: rect.left + scrollLeft
    };

    window.scrollTo({
      top:  position.top,
      left: position.left,
      behavior: 'smooth'
    });
  }

  filterSlotsFor(timeOfDay) {
    const slotMapping = {
      'morning':    [0,1,2,3,4,5,6,7,8,9,10,11],
      'afternoon':  [12,13,14,15,16,17],
      'evening':    [18,19,20,21,22,23]
    }

    this.timeSlotTargets.forEach((ts, index) => {
      if (!slotMapping[timeOfDay].includes(parseInt(ts.dataset.timeSlot))) {
        ts.classList.add('d-none');
      } else {
        ts.classList.remove('d-none');
      }
    })

    const visible = this.timeSlotTargets.filter(ts => !ts.classList.contains('d-none'));

    if (visible[0]) {
      visible[0].querySelector('input').checked = true;
    } else {
      this.noSlotsTarget.classList.remove('d-none');
    }

  }

  filterSlots(e) {
    this.noSlotsTarget.classList.add('d-none');
    this.filterSlotsFor(e.target.htmlFor);
  }

  selectedProduct() {
    return this.productTargets.find(x => {
      return x.querySelector('input:checked');
    });
  }

  resetAvailability() {
    if (this.selectedDate()) {
      const dateInput     = this.selectedDate();
      dateInput.checked   = false;

      dateInput.parentElement.style.backgroundColor = '#f8f9fa';
    }

    this.formDetailsTarget.classList.add('d-none');
    this.emptyStateTarget.classList.remove('d-none');
  }

  selectedDate() {
    const dates = document.querySelectorAll('[data-date-item]');

    return Array.from(dates).find(x => {
      return x.checked;
    });
  }

  selectedMerchantId() {
    return document.querySelector('select#merchant_id').querySelector('option[selected="selected"]').value;
  }
}
