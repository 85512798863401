import { Controller } from "@hotwired/stimulus";
const Uppy                = require('@uppy/core');
const Dashboard           = require('@uppy/dashboard');
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload');

export default class extends Controller {
  static targets = ['file', 'documentUploading', 'documentUploadingSvg', 'documentMainContent']

  connect() {
    if (!this.uppy) {
      this.uppy = new Uppy({
        id: 'DOCUMENTS',
        autoProceed: false,
        allowMultipleUploads: false,
        allowMultipleUploadBatches: false,
        // logger: Uppy.debugLogger,
        onBeforeFileAdded: (file) => {
          const valid = this.validateType(file);

          if (valid)
            return file;

          this.uppy.info('Invalid file type: only csv allowed');
          return false;
        },
        onBeforeUpload: (files) => {
          const valid = this.validateTotalSize(files);

          if (valid)
            return files;

          this.uppy.info('Total file size too big: Max 5mb');
          return false;
        },
      });

      document.querySelectorAll('[data-uppy]').forEach(el => this.setupUppy(el));
    }
  }

  disconnect() {
    if (this.uppy)
      this.uppy.close({ reason: 'Documents disconnected' });
  }

  setupUppy(el) {
    let trigger         = el.querySelector('[data-behavior="uppy-trigger"]');
    let form            = el.closest('form');
    let directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content");
    let fieldName       = el.dataset.uppy;

    if (trigger)
      trigger.addEventListener('click', (e) => e.preventDefault());

    if (directUploadUrl)
      this.uppy.use(ActiveStorageUpload, {
        id: `ActiveStorageUploadDocs_${fieldName}`,
        directUploadUrl: directUploadUrl
      });

    if (trigger)
      this.uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: true,
        allowMultipleUploadBatches: false,
        autoOpenFileEditor: false,
        allowMultipleUploads: false
      });

    this.uppy.on('complete', (result) => {
      el.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element));

      result.successful.forEach((file) => {
        this.appendUploadedFile(el, file, fieldName);
      });

      this.showLoading();

      this.uppy.reset();
      form.submit();
    });
  }

  showLoading() {
    this.documentUploadingSvgTarget.innerHTML = this.lottieFileUploading();
    this.documentMainContentTarget.classList.add('d-none');
    this.documentUploadingTarget.classList.remove('d-none');
  }

  lottieFileUploading() {
    return ' \
      <lottie-player \
        src="/vendor/lottie/doc_upload.json" \
        background="transparent" \
        speed="1" \
        loop="true" \
        style="width: 100%; height: 300px;" \
        autoplay \
      ></lottie-player>';
  }

  appendUploadedFile(element, file, fieldName) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);

    element.appendChild(hiddenField);
  }

  setPreview(element, file) {
    const defaultImageUrl = document.querySelector('[data-default-logo-url]').dataset.defaultLogoUrl;
    let preview           = element.querySelector('[data-behavior="uppy-preview"]');

    if (preview) {
      let src = (file.preview) ? file.preview : defaultImageUrl
      preview.src = src
    }
  }

  validateTotalSize(files) {
    const max_size = 5 * 1024 * 1024;

    const total_size = Object.keys(files).reduce((total, file) => {
      return total += files[file].size;
    }, 0);

    return total_size < max_size;
  }

  validateType(file) {
    const valid_types = ['text/csv'];
    return valid_types.includes(file.type);
  }
}
