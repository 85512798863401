import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { loading } from '../utils/_loading';

export default class extends Controller {
  static targets          = ['display', 'product', 'prompt', 'result', 'resultText', 'resultValue', 'productRewardValue'];
  COMPLETE_NUMBER_REGEX   = /^\d+\.\d{2,}$/;
  INCOMPLETE_NUMBER_REGEX = /^\d+\.\d{1,1}$/;
  SUCCESS_MESSAGE         = '🙌';

  addNumber(event) {
    if (this.COMPLETE_NUMBER_REGEX.test(this.displayTarget.innerHTML)) {
      return;
    }

    const value = event.target.dataset.numberpadValue;

    if (this.INCOMPLETE_NUMBER_REGEX.test(this.displayTarget.innerHTML)) {
      if (value === '00' ) {
        const currentValue = this.displayTarget.innerHTML;
        this.displayTarget.innerHTML = currentValue.slice(0, -1);

        this.displayTarget.innerHTML += '00';
        return;
      }

      this.displayTarget.innerHTML += event.target.dataset.numberpadValue;
    } else {
      if (this.displayTarget.innerHTML === '0' || this.displayTarget.innerHTML === this.SUCCESS_MESSAGE) {
        this.displayTarget.innerHTML = value.toString();
      } else {
        this.displayTarget.innerHTML += value.toString();
      }
    }
  }

  backspace() {
    if (this.displayTarget.innerHTML.length === 1 || this.displayTarget.innerHTML === this.SUCCESS_MESSAGE) {
      this.displayTarget.innerHTML = '0';
      return;
    }

    const currentValue = this.displayTarget.innerHTML;

    this.displayTarget.innerHTML = currentValue.slice(0, -1);
  }

  generateCode() {
    this.triggerLoading();

    const value = this.displayTarget.innerHTML;
    const form  = new FormData();

    form.append('product_id', this.productTarget.value);
    form.append('purchase_value', value);

    Rails.ajax({
      type:     'post',
      dataType: 'json',
      url:      '/api/v1/rewards/generate_code',
      data:     form,
      success: (response) => {
        this.displayTarget.innerHTML      = this.SUCCESS_MESSAGE;
        this.resultTextTarget.innerHTML   = response.data.qr_code;
        this.resultValueTarget.innerHTML  = response.data.value;

        this.promptTarget.classList.add('d-none');
        this.resultTarget.classList.remove('d-none');
      },
      error: (_response) => {
        console.error('Unable to create code');
      },
    });
  }

  triggerLoading() {
    this.promptTarget.classList.remove('d-none');
    this.resultTarget.classList.add('d-none');

    this.promptTarget.innerHTML = loading('Generating Code');
  }

  productChange(event) {
    const options          = event.target.options;
    const selectedOptions  = Array.from(options).filter((option) => option.selected);
    const rewardValue      = selectedOptions[0].dataset.numberpadReward;

    this.productRewardValueTargets.forEach((target) => {
      target.innerHTML = `${rewardValue}%`;
    })
  }
}
