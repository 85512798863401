import { Controller } from "@hotwired/stimulus";
const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload');
const ImageEditor =  require('@uppy/image-editor');

export default class extends Controller {
  static targets = ['file', 'documentUploading', 'documentUploadingSvg', 'documentMainContent']

  connect() {
    if (!this.uppy) {
      this.uppy = new Uppy({
        id: 'Logo',
        autoProceed: false,
        allowMultipleUploads: false,
        // logger: Uppy.debugLogger,
        allowMultipleUploadBatches: false
      });

      document.querySelectorAll('[data-uppy]').forEach(el => this.setupUppy(el));
    }
  }

  disconnect() {
    if (this.uppy)
      this.uppy.close({ reason: 'logo disconnected' })
  }

  setupUppy(el) {
    let trigger = el.querySelector('[data-behavior="uppy-trigger"]');
    let form = el.closest('form');
    let directUploadUrl = document.querySelector("meta[name='direct-upload-url']").getAttribute("content");
    let fieldName = el.dataset.uppy;

    if (trigger)
      trigger.addEventListener('click', (e) => e.preventDefault());

    if (directUploadUrl)
      this.uppy.use(ActiveStorageUpload, {
        id: 'ActiveStorageUpload',
        directUploadUrl: directUploadUrl
      });

    if (trigger)
      this.uppy.use(Dashboard, {
        trigger: trigger,
        closeAfterFinish: true,
        allowMultipleUploadBatches: false,
        autoOpenFileEditor: true,
        allowMultipleUploads: false
      });

    this.uppy.use(ImageEditor, {
      id: 'ImageEditor',
      target: Dashboard,
      quality: 0.8,
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: 1
      }
    })

    this.uppy.on('complete', (result) => {
      el.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element));

      result.successful.forEach((file) => {
        this.appendUploadedFile(el, file, fieldName);
        this.setPreview(el, file);
      });

      this.showLoading();

      this.uppy.reset();
      form.submit();
    });
  }

  appendUploadedFile(element, file, fieldName) {
    const hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);

    element.appendChild(hiddenField);
  }

  setPreview(element, file) {
    const defaultImageUrl = document.querySelector('[data-default-logo-url]').dataset.defaultLogoUrl;
    let preview           = element.querySelector('[data-behavior="uppy-preview"]');

    if (preview) {
      let src = (file.preview) ? file.preview : defaultImageUrl
      preview.src = src
    }
  }

  showLoading() {
    this.documentUploadingSvgTarget.innerHTML = this.lottieFileUploading();
    this.documentMainContentTarget.classList.add('d-none');
    this.documentUploadingTarget.classList.remove('d-none');
  }

  lottieFileUploading() {
    return ' \
      <lottie-player \
        src="/vendor/lottie/doc_upload.json" \
        background="transparent" \
        speed="1" \
        loop="true" \
        style="width: 100%; height: 300px;" \
        autoplay \
      ></lottie-player>';
  }

  enableSubmit() {
    const button = document.querySelector('[data-submit]');
    button.disabled = false;

    document.querySelector('[data-save-notice]').classList.remove('d-none');
  }
}
