import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['valueField', 'promoCodeField', 'typeField'];

  connect() {
    const currentType = this.typeFieldTarget.value;
    this.selectType(currentType);
  }


  selectType(eventOrString) {
    let typeValue;
    typeof eventOrString === 'string'
      ? (typeValue = eventOrString)
      : (typeValue = eventOrString.target.value);

    switch(typeValue) {
      case 'promo_code':
        this.disableFields([this.valueFieldTarget]);
        this.enableFields([this.promoCodeFieldTarget]);
        break;
      default:
        this.disableFields([this.promoCodeFieldTarget]);
        this.enableFields([this.valueFieldTarget]);
        break;
    }
  }

  disableFields(fields) {
    fields.forEach(field => {
      field.closest('.wui-form__group').classList.add('d-none');
      field.disabled = true;
    })
  }

  enableFields(fields) {
    fields.forEach((field) => {
      field.closest('.wui-form__group').classList.remove('d-none');
      field.disabled = false;
    });
  }
}
